/**
 * @Description 套餐配置
 * @Author zhikangfan
 */


/**
 * VIP 等级定义
 */
export const VIP_LEVEL = {
  NON_VIP: 0, // 非vip
  COUNT_VIP: 5, // 次数vip
  TIME_VIP: 10, // 时间vip
  PERMANENT_VIP: 100, // 永久会员
  THREE_DAY_VIP: 7, // 3天会员
  YEAR_VIP: 10, // 年会员
}

/**
 * 普通套餐
 */
export const packageList  = [
  {
    id: 1,
    defaultChecked: true,
    title: '终身会员',
    feature: '支持2人同时使用',
    description: '¥0.01/天',
    corner: '限时促销',
    price: 69,
    originPrice: 569,
    discountPriceNum: '500',
    discountPrice: '直降500元',
    unitPrice: '69',
    unit: '',
    cornerImg: "https://res.yunkun.cn/img_geshicn/img/500.svg",
    activity: true,
    showCountDown: true, // 是否显示倒计时
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.PERMANENT_VIP]
  },
  {
    id: 10,
    title: '升级终身会员',
    description: '¥0.01/天',
    defaultChecked: true,
    unitPrice: '39',
    unit: '',
    originPrice: 589,
    discountPrice: '已优惠¥550元',
    corner: '限时升级',
    price: 39,
    discountPriceNum: '550',
    activity: true,
    showCountDown: true, // 是否显示倒计时
    level: [VIP_LEVEL.COUNT_VIP, VIP_LEVEL.THREE_DAY_VIP, VIP_LEVEL.YEAR_VIP]
  },
  {
    id: 2,
    title: '年会员',
    description: '低至0.13元/天',
    corner: '',
    price: 49,
    originPrice: 179,
    discountPrice: '立减130元',
    unitPrice: '49',
    unit: '',
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.PERMANENT_VIP]
  },
  {
    id: 8,
    title: '按张付费·10张',
    description: '下载张数永久有效',
    corner: '',
    price: 19.9,
    originPrice: '99.9',
    discountPrice: '',
    unitPrice: 1.99,
    unit: '/张',
    defaultChecked: true,
    level: [VIP_LEVEL.COUNT_VIP, VIP_LEVEL.THREE_DAY_VIP]
  },
  {
    id: 4,
    title: '按张付费·10张',
    description: '下载张数永久有效',
    corner: '',
    price: 19.9,
    originPrice: '99.9',
    discountPrice: '',
    unitPrice: '1.99',
    unit: '/张',
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.TIME_VIP, VIP_LEVEL.PERMANENT_VIP]
  },
  // {
  //   id: 11,
  //   title: '3天会员',
  //   description: '3天不限次数使用',
  //   unitPrice: 19.9, // 单价
  //   originPrice: '39.9', // 原价
  //   price: 19.9, // 售价
  //   discountPrice: '',
  //   unit: '',
  //   corner: '',
  //   defaultChecked: false,
  //   level: [VIP_LEVEL.NON_VIP]
  // },
  // {
  //   id: 99,
  //   title: '按张付费·20张',
  //   description: '张数永不过期',
  //   unitPrice: 1, // 单价
  //   originPrice: '99.9', // 原价
  //   price: 19.9, // 售价
  //   discountPrice: '',
  //   unit: '/张',
  //   corner: '',
  //   defaultChecked: false,
  //   level: [VIP_LEVEL.NON_VIP],
  //   divisor: true, // 是否显示约等于符号
  // },
  {
    id: 3,
    title: '按张付费·1张',
    description: '下载张数永久有效',
    corner: '',
    price: 9.9,
    originPrice: '',
    discountPrice: '',
    unitPrice: 9.9,
    unit: '/张',
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.TIME_VIP, VIP_LEVEL.PERMANENT_VIP]
  },

  // {
  //   id: 89,
  //   title: '按张付费·1张',
  //   description: '买1张送1张',
  //   unitPrice: 9.9, // 单价
  //   originPrice: '19.9',
  //   price: 9.9,
  //   discountPrice: '',
  //   unit: '/张',
  //   corner: '',
  //   defaultChecked: false,
  //   level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.YEAR_VIP]
  // },

]

/**
 * 挽留套餐
 */
export const retentionList = [
  {
    id: 5,
    title: '终身会员',
    description: '永久使用，限时促销',
    unitPrice: 39, // 单价
    originPrice: '569', // 原价
    price: 39, // 售价
    discountPrice: '',
    unit: '',
    corner: '一年仅一次',
    defaultChecked: true,
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP]
  },
  {
    id: 11,
    title: '3天会员',
    description: '不限次数',
    unitPrice: 19.9, // 单价
    originPrice: '', // 原价
    price: 19.9, // 售价
    discountPrice: '',
    unit: '',
    corner: '',
    defaultChecked: false,
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP]
  },
]

/**
 * 挽留套餐，但是必须只有前两次打开使用
 */
export const retentionList2 = [
  {
    id: 49,
    title: '终身会员',
    description: '永久使用，限时促销',
    unitPrice: 59, // 单价
    originPrice: '569', // 原价
    price: 59, // 售价
    discountPrice: '',
    unit: '',
    corner: '一年仅一次',
    defaultChecked: true,
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP]
  },
  {
    id: 11,
    title: '3天会员',
    description: '不限次数',
    unitPrice: 19.9, // 单价
    originPrice: '', // 原价
    price: 19.9, // 售价
    discountPrice: '',
    unit: '',
    corner: '',
    defaultChecked: false,
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP]
  },
]

export const sougou_packageList = [

  {
    id: 76,
    defaultChecked: true,
    activity: true,
    title: '终身会员',
    description: '¥0.01/天',
    corner: '限时促销',
    cornerImg: "https://res.yunkun.cn/img_geshicn/img/500.svg",
    price: 69,
    originPrice: 569,
    discountPriceNum: '500',
    discountPrice: '直降500元',
    unitPrice: '69',
    unit: '',
    showCountDown: true, // 是否显示倒计时
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.PERMANENT_VIP]
  },
  {
    id: 10,
    defaultChecked: true,
    title: '终身会员',
    description: '¥0.01/天',
    corner: '限时升级',
    price: 39,
    originPrice: 589,
    discountPrice: '已优惠¥550元',
    unitPrice: '39',
    unit: '',
    activity: true,
    discountPriceNum: '550',
    showCountDown: true, // 是否显示倒计时
    level: [VIP_LEVEL.COUNT_VIP, VIP_LEVEL.THREE_DAY_VIP, VIP_LEVEL.YEAR_VIP]
  },

  {
    id: 75,
    title: '年会员',
    description: '折合：0.13元/天',
    corner: '',
    price: 49,
    originPrice: 179,
    discountPrice: '立减130元',
    unitPrice: '49',
    unit: '',
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.PERMANENT_VIP]
  },

  {
    id: 8,
    title: '按张付费·10张',
    description: '下载张数永久有效',
    corner: '',
    price: 19.9,
    originPrice: '99.9',
    discountPrice: '',
    unitPrice: 1.99,
    unit: '/张',
    level: [VIP_LEVEL.COUNT_VIP, VIP_LEVEL.THREE_DAY_VIP]
  },
  {
    id: 72,
    title: '按张付费·10张',
    description: '下载张数永久有效',
    corner: '',
    price: 19.9,
    originPrice: '99.9',
    discountPrice: '',
    unitPrice: '1.99',
    unit: '/张',
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.TIME_VIP, VIP_LEVEL.PERMANENT_VIP]
  },

  {
    id: 74,
    title: '3天体验VIP',
    description: '不限次数',
    corner: '',
    price: 29,
    originPrice: 49,
    discountPrice: '已优惠20元',
    unitPrice: '29',
    unit: '',
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.TIME_VIP, VIP_LEVEL.PERMANENT_VIP]
  },
  {
    id: 73,
    title: '按张付费·1张',
    description: '下载张数永久有效',
    corner: '',
    price: 9.9,
    originPrice: '',
    discountPrice: '',
    unitPrice: 9.9,
    unit: '/张',
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.TIME_VIP, VIP_LEVEL.PERMANENT_VIP]
  },
  // {
  //   id: 89,
  //   title: '按张付费·1张',
  //   description: '买1张送1张',
  //   unitPrice: 9.9, // 单价
  //   originPrice: '19.9',
  //   price: 9.9,
  //   discountPrice: '',
  //   unit: '/张',
  //   corner: '',
  //   defaultChecked: false,
  //   level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.YEAR_VIP]
  // },
]

export const sougou_retentionList =   [
  {
    id: 78,
    defaultChecked: true,
    title: '终身会员',
    description: '永久使用，限时促销',
    corner: '一年仅一次',
    price: 39,
    originPrice: 569
  },
  {
    id: 77,
    defaultChecked: false,
    title: '3天会员',
    description: '不限次数',
    corner: '',
    price: 19.9,
    originPrice: '',
  },
]